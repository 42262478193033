<script>
import Cookies from 'js-cookie';

export default {
  name: 'Reset',
  mounted() {
    Object.keys(Cookies.get()).forEach((cookieName) => {
      if (process.env.NODE_ENV === 'development') {
        Cookies.remove(cookieName, { path: '/', domain: 'localhost' });
      } else {
        Cookies.remove(cookieName, { path: '/', domain: '.ocard.co' });
      }

      window.localStorage.removeItem('vuex');

      this.$nextTick(() => {
        this.$router.push({ name: 'Home' });
      });
    });
  },
};
</script>
